/**
 * Render a Study page.
 * Supported props: ctx pagepath media
 */
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from './StudyLayout';
import PageHead from './PageHead';
import PageSection from './PageSection';
import Banner from './PageBanner';
// import Safe from './Safe';
import Screener from './Screener';
import { localizer } from '../util/localizer';
import { mergeSectionsWithImages } from '../util/rendertools';

export default function StudyPage(props) {
  const studyCfg = props.ctx.config;
  const localized = localizer(props.ctx);
  /*
  const sectionCnt = studyCfg.sections.length;
  let middleImg = null;
  let lowerImg = null;
  if (Array.isArray(props.media) && props.media.length>0) {
    middleImg = props.media.shift()
    lowerImg = props.media.shift() || null;
  }
  */
  const lastSec = 'img-3'; // studyCfg.sections[studyCfg.sections.length-1].id;
  const sections = mergeSectionsWithImages(
    studyCfg.sections.map((section, index) => <PageSection key={section.id} config={section} />),
    getImages,
    [1,4]
  );

  return (
    <Layout config={studyCfg} ctx={props.ctx} currentpath={props.pagepath} fpOn="banner" fpOff={lastSec}>
      <PageHead lang={props.ctx.locale} sitename={studyCfg.title} siteurl={studyCfg.siteurl} meta={studyCfg.metaDescription} />
      <Banner config={studyCfg.banner} />
      {sections}
      <div id="screener">
        <Screener config={studyCfg.screener} sitedata={studyCfg} localizerfn={localized} />
      </div>
    </Layout>
  );
};

function getImages() {
  function makeDiv(elem, id) { return <div className="-fluid" id={id} key={id}>{elem}</div>; }
  const imgs = [
    makeDiv(<StaticImage src='../images/ctximg_2.jpg' alt="" className="ctximg" />, 'img-2'),
    makeDiv(<StaticImage src='../images/ctximg_3.jpg' alt="" className="ctximg" />, 'img-3')
  ];
  return function() { return imgs.shift(); }
}
