/**
 * Utility for constructing form fields.
 */
import React from 'react';
import useValidation from '../components/useValidation';
import PhoneField from '../components/PhoneField';
import SelectBox from '../components/SelectBox';
import PlaceInput from '../components/PlaceInput';
import RadioList from '../components/RadioList';
import Checkbox from '../components/Checkbox';
import CheckList from '../components/CheckList';
import DatePicker from '../components/DatePicker';
import { css } from './pagetools';

const REQUIRED = <span className="error-msg" title="This field is required.">*</span>;

export default function init(fieldMetadata, formData, setter, addressData, addressSetter, isQualified) {
  const vm = useValidation(formData);
  const elems = makeElements();
  const addressField = fieldMetadata.find(field => field.type==='address');

  /**
   * Convenience function to render a text input field.
   * @param object - a configuration object for a field
   * @param function - a function to be assigned to onChange and onBlur
   * @return JSX
   */
  function makeInput(field, validationRule) {
    if (!field.id) return <div>Field is not defined</div>;
    let comp = null;
    if (field.validation) {
      if (validationRule) vm.addValidation(field.id, validationRule);
      else                vm.setNonBlank(field.id);
      comp = (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label} {REQUIRED}</label>
          <input type="text" name={field.id} id={field.id} className="form-control" aria-required="true"
                 onChange={validateInput} onBlur={validateInput} value={formData[field.id]||''} ref={vm.getRef(field.id)} />
          <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
        </div>
      );
    } else {
      comp = (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label}</label>
          <input type="text" name={field.id} id={field.id} className="form-control" aria-required="true"
                 onChange={validateInput} onBlur={validateInput} value={formData[field.id]||''} />
        </div>
      );
    }
    return showOnQualify(field.showOnQualify, comp);
  }

  function makePhoneInput(field) {
    if (!field.id) return <div>Field is not defined</div>;
    vm.setNonBlank(field.id);
    const MAX_LEN=11; // areacode+7+countryprefix
    function validate(e) {
      const value = e.target.value;
      if (value.length>MAX_LEN) return;
      const notNum = isNaN(Number(value));
      if (notNum || (value.indexOf('.')>=0)) return;
      setter({...formData, [e.target.name]: value});
      const isFieldOk = (value.length>=10);
      vm.setError(e.target.name, !isFieldOk);
    }
    const comp = (
      <div className="form-group-2">
        <div className="phone-hint-container">
          <label className="fg-1" htmlFor={field.id}>{field.label} {REQUIRED}</label>
          {field.hint && <span className="phone-hint">{field.hint}</span>}
        </div>
        <input type="text" name={field.id} id={field.id} className="form-control" aria-required="true"
                 onChange={validate} value={formData[field.id]||''} ref={vm.getRef(field.id)} />
        <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
      </div>
    );
    return showOnQualify(field.showOnQualify, comp);
  }

  function makeSelect(field, onChange) {
    if (!field.id) return <div>Field is not defined</div>;
    if (field.validation) vm.setNonBlank(field.id);
    const comp = (
      <div className="form-group-2">
        {field.label &&
          <label htmlFor={field.id}>{field.label} {field.validation ? REQUIRED : null}</label>
        }
        <SelectBox name={field.id} id={field.id}
          items={field.choices || field.options}
          defaultItem={field.default}
          onChangeBlur={onChange}
          focusRef={vm.getRef(field.id)}
          errorFn={vm.getError}
          errorMessage={field.validation}
          theme={field.theme} />
      </div>
    );
    return showOnQualify(field.showOnQualify, comp);
  }

  function makeAddressInput(field) {
    if (!field.id) return <div>Field is not defined</div>;
    vm.setNonBlank(field.id);
    function onChangeBlur(data) { addressSetter(data); };
    // Only validates the text input string value, not valid object from API call
    function validatePlaceInput(event) {
      const isFieldOk = vm.getRule(field.id).test(event.target.value);
      vm.setError(event.target.name, !isFieldOk);
      if (!isFieldOk) addressSetter(null);
      return isFieldOk;
    }
    const rootAttribs = {
      id: field.id,
      onChangeBlur: onChangeBlur,
      onValidate: validatePlaceInput,
      focusRef: vm.getRef(field.id)
    };
    if (field.countries) rootAttribs.countries = field.countries;
    const comp = (
      <div className="form-group-2">
        <label htmlFor={field.id}>{field.label} {field.validation ? REQUIRED : null}</label>
        <PlaceInput {...rootAttribs} />
        <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
      </div>
    );
    return showOnQualify(field.showOnQualify, comp);
  }

  function makeRadioButtons(field) {
    if (!field.id) return <div>Radio buttons field is not defined</div>;
    let comp = null;
    if (field.validation) {
      vm.setNonBlank(field.id);
      comp = (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label} {REQUIRED}</label>
          <RadioList className="flx" category={field.id} items={field.choices} onClick={validateInput} theme="mx-10" />
          <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
        </div>
      );
    } else {
      comp = (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label}</label>
          <RadioList className="flx" category={field.id} items={field.choices} onClick={saveInput} theme="mx-10" />
        </div>
      );
    }
    return showOnQualify(field.showOnQualify, comp);
  }

  function makeCheckbox(field) {
    if (!field.id) return <div>Field is not defined</div>;
    let comp = null;
    function makeCheckboxHandler() {
      if (field.validation) {
        return function(setting) {
          setter({...formData, [field.id]: Boolean(setting).toString()});
          vm.validateField(field.id, setting);
        };
      }
      return function(setting) { setter({...formData, [field.id]: Boolean(setting).toString()}); };
    }
    const fn = makeCheckboxHandler();
    if (field.validation) {
      vm.setNonBlank(field.id);
      comp = (
        <div className="form-group-2">
          <Checkbox name={field.id}
            label={field.label}
            onChange={fn}
            focusRef={vm.getRef(field.id)}
            errorFn={vm.getError}
            errorMessage={field.validation} />
        </div>
      );
    } else {
      comp = (
        <div className="form-group-2">
          <Checkbox name={field.id} label={field.label} onChange={fn} />
        </div>
      );
    }
    return showOnQualify(field.showOnQualify, comp);
  }

  function makeCheckList(field) {
    if (!field.id) return <div>Field is not defined</div>;
    let comp = null;
    function onChangeValidated(choices) {
      const fieldvalue = (Array.isArray(choices) ? choices.join(', ') : '');
      setter({...formData, [field.id]: fieldvalue});
      vm.validateField(field.id, fieldvalue);
    };
    function onChange(choices) {
      const fieldvalue = (Array.isArray(choices) ? choices.join(', ') : '');
      setter({...formData, [field.id]: fieldvalue});
    }
    const fn = (field.validation ? onChangeValidated : onChange);

    if (field.validation) {
      vm.setNonBlank(field.id);
      comp = (
        <div id={field.id} className="form-group-2">
          <label htmlFor={field.id}>{field.label} {REQUIRED}</label>
          <CheckList items={field.options} onChange={fn} />
          <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
        </div>
      );
    } else {
      comp = (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label}</label>
          <CheckList items={field.options} onChange={fn} />
        </div>
      );
    }
    return showOnQualify(field.showOnQualify, comp);
  }

  function makeDateField(field) {
    if (!field.id) return <div>Field is not defined</div>;
    let comp = null;
    function onDateBlur(event) {
      const fieldvalue = (formData[event.target.id] ? formData[event.target.id].toLocaleDateString() : '');
      vm.validateField(event.target.id, fieldvalue);
    }
    function makeDateHandler() {
      return function(event) {
        const fieldvalue = new Date(event).toLocaleDateString();
        setter({...formData, [field.id]: event});
        if (field.validation) {
          const validator = vm.getRule(field.id);
          const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
          vm.setError(field.id, !isFieldOk);
        }
      };
    }
    const handler = makeDateHandler();
    if (field.validation) {
      vm.setNonBlank(field.id);
      comp = (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label} {REQUIRED}</label>
          <DatePicker name={field.id} value={formData[field.id]} onChange={handler} onBlur={onDateBlur} theme="form-control" />
          <div className={css('error-msg', (vm.getError(field.id)?'vis':'hid'))}>{field.validation}</div>
        </div>
      );
    } else {
      comp = (
        <div className="form-group-2">
          <label htmlFor={field.id}>{field.label}</label>
          <DatePicker name={field.id} value={formData[field.id]} onChange={handler} theme="form-control" />
        </div>
      );
    }
    return showOnQualify(field.showOnQualify, comp);
  }

  function validateForm() {
    vm.setFormInteracted(true);
    // Collect an array of field names and their statuses
    let getFieldValue = function(fieldname) { return formData[fieldname]; };
    if (addressField) getFieldValue = function(fieldname) {
      if (fieldname!==addressField.id) return formData[fieldname];
      else if (!addressData) return '';
      else if (addressData.hasOwnProperty('full')) return addressData.full;
      else return '';
    };
    const updatedErrors = vm.getFields().reduce((status, field) => {
      const fieldvalue = getFieldValue(field);
      const validator = vm.getRule(field);
      const isFieldOk = (
        (field==='phone') ? (fieldvalue.length===10 || fieldvalue.length===11)
        :
        (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false)
      );
      // devHelp(field, fieldvalue, isFieldOk);
      status[field] = !isFieldOk;
      return status;
    }, {});
    vm.setAllErrors(updatedErrors);
    const inValidFields = Object.keys(updatedErrors).filter(key => updatedErrors[key]);
    if (inValidFields.length===0) return true;
    const firstError = vm.getRef(inValidFields[0]).current; // Focus on first invalid field
    if (!firstError) return false;
    else if (firstError.focus) firstError.focus();
    else if (firstError.getInputDOMNode) firstError.getInputDOMNode().focus();
    return false;
  }

  function devHelp(field, value, isOk) {
    const info = ((typeof value)==='object' ? `${Object.keys(value).length} fields` : value);
    console.log(`VF: ${field} = "${info}" ..`, isOk);
  }

  function validateInput(event) {
    const fieldvalue = event.target.value;
    setter({...formData, [event.target.name]: fieldvalue});
    const validator = vm.getRule(event.target.name);
    if (!validator) return;
    const isFieldOk = (Boolean(fieldvalue) ? (validator ? validator.test(fieldvalue) : true) : false);
    vm.setError(event.target.name, !isFieldOk);
  }

  function saveInput(event) { setter({...formData, [event.target.name]: event.target.value}); }

  function showOnQualify(setting, component) {
    if (isBlank(setting)) {
      return component;
    } else {
      if (setting) return isQualified && component;
      else         return !isQualified && component;
    }
  }

  function isBlank(obj) { return (obj===null || obj===undefined); }

  function input(field, isFullWidth) { elems.add(makeInput(field), isFullWidth); }

  function phone(field, isFullWidth) { elems.add(makePhoneInput(field), isFullWidth); }

  function email(field, isFullWidth) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    elems.add(makeInput(field, emailRegex), isFullWidth);
  }

  function select(field, isFullWidth) { elems.add(makeSelect(field, (field.validation ? validateInput : saveInput)), isFullWidth); }

  function address(field, isFullWidth) { elems.add(makeAddressInput(field), isFullWidth); }

  function radio(field, isFullWidth) { elems.add(makeRadioButtons(field), isFullWidth); }

  function checkbox(field, isFullWidth) { elems.add(makeCheckbox(field), isFullWidth); }

  function checklist(field, isFullWidth) { elems.add(makeCheckList(field), isFullWidth); }

  function date(field, isFullWidth) { elems.add(makeDateField(field), isFullWidth); }

  function render() {
    if (!Array.isArray(fieldMetadata)) {
      return <div>Fields not properly configured. Does configuration file have an array property called <code>formfields</code>?</div>;
    }
    fieldMetadata.forEach(field => {
      const fieldType = field.type || 'input';
      if (fieldType==='address') address(field, field.fullWidth);
      else if (fieldType==='checkbox') checkbox(field, field.fullWidth);
      else if (fieldType==='checklist') checklist(field, field.fullWidth);
      else if (fieldType==='date') date(field, field.fullWidth);
      else if (fieldType==='email') email(field, field.fullWidth);
      else if (fieldType==='phone') phone(field, field.fullWidth);
      else if (fieldType==='radio') radio(field, field.fullWidth);
      else if (fieldType==='select') select(field, field.fullWidth);
      else input(field, field.fullWidth);
    });
    return elems.render();
  }

  function makeElements() {
    const collection = [];
    function add(content, isFullWidth) { collection.push({content, isFullWidth}); }
    function render() {
      return collection.map((item, index) => (<div key={`f-${index}`} className={css('p-grid', item.isFullWidth?'form-group-fullw':'')}>{item.content}</div>));
    }
    return {add, render};
  }

  return {validateForm, render};
}
